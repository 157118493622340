.container {
  display: flex;
  transition: all 0.3s ease;
}

.sidebar {
  transition: all 0.3s ease;
  display: flex;
  border-top-right-radius: 10px;
  max-height: 100vh !important;
  flex-direction: column;
}

.collapsed .sidebar {
  height: 0 !important;
  width: 0px;
  overflow: hidden;

}

.calendar {
  flex: 1;
  background-color: white;
  transition: all 0.3s ease;
}

.group-item {
  padding: 2px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ccc;
}
.scroleable-list {
  overflow-x: hidden;
  overflow-y: scroll; /* Habilitar desplazamiento vertical */
  scrollbar-width: thin; /* Firefox */
  padding-left: 10%;
  padding-right: 10%;
}

/* Webkit-based browsers (Chrome, Safari) */
.scroleable-list::-webkit-scrollbar {
  width: 2px;
  background: transparent;
}

.scroleable-list::-webkit-scrollbar-thumb {
  background: transparent;
  opacity: 0.5;
  width: 2px;
}

.scroleable-list:hover::-webkit-scrollbar-thumb {
  background: #333;
  opacity: 0.5;
  width: 2px;
}

button {
  margin: 10px;
}
