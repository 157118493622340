
.container {
    font-family: 'Roboto', sans-serif;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .material-textfield {
    position: relative;  
  }
  
  .calendar_label > label {
    position: absolute;
    font-size: 1rem;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    color: gray;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: .1s ease-out;
    transform-origin: left top;
    pointer-events: none;
  }
  .calendar_input {
    font-size: 1rem;
    outline: none;
    border: 1px solid gray;
    border-radius: 5px;  
    padding: 1rem 0.7rem;
    color: gray;
    transition: 0.1s ease-out;
  }
  .calendar_label > input:focus {
    border-color: #6200EE;  
  }
  .calendar_label > input:focus + label {
    color: #6200EE;
    top: 0;
    transform: translateY(-50%) scale(.9);
  }
  .calendar_label > input:not(:placeholder-shown) + label {
    top: 0;
    transform: translateY(-50%) scale(.9);
  }